<script setup lang="ts">
import type { MaybeElement } from '@vueuse/core'
import { provide } from 'vue'
import { ref } from '#imports'
import TooltipContainer from '../private/TooltipContent.vue'
import TransitionFade from './transition/Fade.vue'

const props = defineProps<{ disabled?: boolean; offset?: number }>()
const emit = defineEmits<{
  show: []
  hide: []
}>()

const activator = ref<MaybeElement>(null)
const isOpen = ref(false)

provide('anchor', activator)

const hide = () => {
  emit('hide')
  isOpen.value = false
}

const show = async () => {
  if (props.disabled) return

  emit('show')
  isOpen.value = true
}

// https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/tooltip_role
</script>

<template>
  <div
    ref="activator"
    v-bind="{
      tabindex: disabled ? -1 : 0,
    }"
    class="ring-offset-3 ring-secondary-200 inline-block rounded-full outline-none focus:ring-2"
    @focusin="show"
    @focusout="hide"
    @keyup.esc.stop.prevent="hide"
    @mouseenter="show"
    @mouseleave="hide"
  >
    <slot v-bind="{ isOpen, show }" name="activator" />
    <ClientOnly>
      <Teleport to="body">
        <TransitionFade
          enter-active-class="duration-300"
          leave-active-class="duration-250"
        >
          <TooltipContainer
            v-if="!disabled && isOpen"
            :offset="offset"
            @esc="hide"
          >
            <slot />
          </TooltipContainer>
        </TransitionFade>
      </Teleport>
    </ClientOnly>
  </div>
</template>
