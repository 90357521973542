<script setup lang="ts">
import type { Placement } from '@floating-ui/vue'
import {
  arrow,
  autoUpdate,
  flip,
  offset,
  shift,
  useFloating,
} from '@floating-ui/vue'
import type { MaybeElement } from '@vueuse/core'
import type { Ref } from 'vue'
import { inject } from 'vue'
import { computed, ref } from '#imports'

const props = defineProps<{
  placement?: Placement
  offset?: number
}>()

const anchor = inject<Ref<Element>>('anchor')

const tooltip = ref<HTMLElement>()
const arrowElement = ref<MaybeElement>(null)

const { isPositioned, middlewareData, placement, strategy, x, y } = useFloating(
  anchor!,
  tooltip,
  {
    placement: props.placement || 'top',
    middleware: [
      flip(),
      offset(props.offset || 12),
      shift({ padding: 8 }),
      arrow({ element: arrowElement }),
    ],
    whileElementsMounted: autoUpdate,
  },
)

const arrowStyles = computed(() => {
  if (!middlewareData.value.arrow) return {}

  const { x, y } = middlewareData.value.arrow!
  const staticSide = {
    top: 'bottom',
    right: 'left',
    bottom: 'top',
    left: 'right',
  }[placement.value.split('-')[0]]

  return {
    display: middlewareData.value.arrow.centerOffset ? 'none' : 'block',
    left: x != null ? `${x}px` : undefined,
    top: y != null ? `${y}px` : undefined,
    right: undefined,
    bottom: undefined,
    [staticSide || 'bottom']: '-8px',
  }
})
</script>

<template>
  <div
    ref="tooltip"
    v-bind="$attrs"
    role="tooltip"
    class="z-100 font-poppins pointer-events-none transform-gpu rounded-md bg-neutral-700 px-3 py-2 text-sm text-white shadow-md transition-opacity duration-300"
    :class="{
      'opacity-0': !isPositioned,
    }"
    :style="{
      position: strategy,
      top: `${y ?? 0}px`,
      left: `${x ?? 0}px`,
      width: 'max-content',
    }"
  >
    <div class="w-max max-w-sm" tabindex="-1">
      <slot />
    </div>
    <div
      ref="arrowElement"
      class="absolute h-4 w-4 rotate-45 transform bg-neutral-700"
      :style="arrowStyles"
    />
  </div>
</template>
